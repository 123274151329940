/**
 * CriteriaServiceRequirementPageV2
 */

import clsx from 'clsx';
import EpiContentArea from 'components/EpiContentArea';
import EpiFragments from 'components/EpiFragments';
import Text from 'components/Text';
import { useIsDesktop, useIsMobile } from 'hooks/useMediaQuery';
import BaseLayout from 'layouts/BaseLayout';
import React, { useEffect, useState } from 'react';
import {
	CriteriaDescriptionModel,
	CriteriaServiceRequirementPageV2Model,
	TabModel,
} from 'types';
import { selectMetaData, selectContent } from 'store/modules/model';
import {
	Button,
	Cell,
	Grid,
	ArticleHeader,
	FilterButton,
	FilterButtonGroup,
	Alert,
} from 'ui-component-library/base';
import {
	CriteriaDescriptionList,
	CriteriaDescriptionTerm,
	CriteriaDescriptionText,
	CriteriaDescriptionWrapper,
	FilterButtonAccordion,
} from 'ui-component-library/uhmse';
import Link from 'components/Link';
import CriteriaCartAddButton from 'components/CriteriaCartAddButton';
import { useSelector } from 'react-redux';

/** Main description for this component. */
const CriteriaServiceRequirementPageV2: React.FC<CriteriaServiceRequirementPageV2Model> =
	({
		heading,
		preamble,
		criteriaInfo,
		levelSwitcherTextWithLinks,
		tabs,
		compareButton,
		addToCartButton,
		contentArea,
		requirementInfo,
		url,
		disclaimer,
	}) => {
		const isDesktop = useIsDesktop();
		const metaData = useSelector(selectMetaData);
		const pageContent = useSelector(selectContent);
		const isMobile = useIsMobile();
		const [activeTab, setActiveTab] = useState<string>('');
		const handleAccordionClick = (tabId: string) => {
			if (activeTab === tabId) {
				setActiveTab('');
			} else {
				const tabEl = document?.getElementById(
					`accordion-button-${tabId}`
				)?.offsetTop;
				if (tabEl) {
					window.scrollTo({
						top: tabEl,
						behavior: 'smooth',
					});
				}
				setActiveTab(tabId);
			}
		};

		useEffect(() => {
			if (isMobile) {
				setActiveTab('');
			} else {
				setActiveTab((tabs && tabs.length > 0 && tabs[0].id) as string);
			}
			// eslint-disable-next-line
		}, [isMobile]);

		useEffect(() => {
			if (pageContent) {
				if (window.dataLayer && window.dataLayer.push && requirementInfo) {
					window.dataLayer.push({
						event: 'Pageview',
						pagePath: window.location.pathname || url,
						pageTitle: metaData?.title,
						pageType: pageContent.modelType,
						criteriaProductArea: requirementInfo.criteriaProductArea,
						criteriaProductGroup: requirementInfo.criteriaProductGroup,
						criteriaProductSubgroup: requirementInfo.criteriaProductSubgroup,
						criteriaItem: requirementInfo.criteriaItem,
						criteriaLevel: requirementInfo.criteriaLevel,
					});
				}
			}
		}, []);

		return (
			<BaseLayout themeBorder={true}>
				<Grid
					padding={false}
					margin={false}
					className="px-4 lg:px-6 -mt-4 md:mt-0"
				>
					<Cell span={12} desktop={7}>
						<Text as="h1" prop="heading">
							{heading}
						</Text>
					</Cell>
				</Grid>
				<Grid
					margin={false}
					padding={false}
					className={clsx('flex-row-reverse px-4 lg:px-6')}
				>
					{isDesktop && (
						<Cell span={4} className="ml-1/12 pl-8">
							{criteriaInfo && (
								<div className="mb-8">
									{criteriaInfo.heading && (
										<h2 className="text-h2 font-semibold mb-4">
											{criteriaInfo.heading}
										</h2>
									)}
									{criteriaInfo.items && criteriaInfo.items.length > 0 && (
										<CriteriaDescriptionList>
											{criteriaInfo.items?.map(
												(item: CriteriaDescriptionModel, index: number) => {
													return (
														<CriteriaDescriptionWrapper key={index}>
															<CriteriaDescriptionTerm text={item.heading} />
															<CriteriaDescriptionText>
																<div
																	className="criteria-description"
																	dangerouslySetInnerHTML={{
																		__html: item.text,
																	}}
																/>
															</CriteriaDescriptionText>
														</CriteriaDescriptionWrapper>
													);
												}
											)}
										</CriteriaDescriptionList>
									)}
								</div>
							)}
							{addToCartButton && addToCartButton.criteriaDescriptor && (
								<div className="mb-6 block">
									<CriteriaCartAddButton
										downloadAddedText={addToCartButton.downloadAddedText}
										addToDownloadsText={addToCartButton.addToDownloadsText}
										criteriaDescriptor={addToCartButton.criteriaDescriptor}
										requirementInfo={requirementInfo}
									/>
								</div>
							)}

							{compareButton && (
								<div className="mb-6 block">
									<Button
										data-button-action={compareButton.text}
										as="a"
										LinkComponent={Link}
										href={compareButton.url}
									>
										{compareButton.text}
									</Button>
								</div>
							)}
						</Cell>
					)}

					<Cell span={12} desktop={7}>
						<div className="mb-10 md:mb-14">
							<ArticleHeader social={true} className="mb-4 md:mb-2">
								{levelSwitcherTextWithLinks && (
									<div
										dangerouslySetInnerHTML={{
											__html: levelSwitcherTextWithLinks,
										}}
										className="richtext"
									/>
								)}
							</ArticleHeader>
							<Text as="preamble" prop="preamble">
								{preamble}
							</Text>
							{disclaimer && (
								<Alert
									variant="danger"
									children={disclaimer}
									className="mb-2"
								/>
							)}
							{!isDesktop && (
								<>
									{criteriaInfo?.heading && (
										<h2 className="text-h2 font-semibold mb-4">
											{criteriaInfo.heading}
										</h2>
									)}
									{criteriaInfo?.items && criteriaInfo?.items.length > 0 && (
										<CriteriaDescriptionList className="mb-8">
											{criteriaInfo.items?.map(
												(item: CriteriaDescriptionModel, index: number) => {
													return (
														<CriteriaDescriptionWrapper key={index}>
															<CriteriaDescriptionTerm text={item.heading} />
															<CriteriaDescriptionText>
																<div
																	className="criteria-description"
																	dangerouslySetInnerHTML={{
																		__html: item.text,
																	}}
																/>
															</CriteriaDescriptionText>
														</CriteriaDescriptionWrapper>
													);
												}
											)}
										</CriteriaDescriptionList>
									)}
									{addToCartButton && addToCartButton.criteriaDescriptor && (
										<div className="mb-6 block">
											<CriteriaCartAddButton
												downloadAddedText={addToCartButton.downloadAddedText}
												addToDownloadsText={addToCartButton.addToDownloadsText}
												criteriaDescriptor={addToCartButton.criteriaDescriptor}
												requirementInfo={requirementInfo}
											/>
										</div>
									)}
									{compareButton && (
										<div className="mb-6 block">
											<Button
												as="a"
												LinkComponent={Link}
												href={compareButton.url}
											>
												{compareButton.text}
											</Button>
										</div>
									)}
								</>
							)}
							{!isMobile ? (
								<>
									{tabs && (
										<FilterButtonGroup>
											{tabs.map((tab: TabModel, index: number) => {
												return (
													<FilterButton
														text={tab.tabName}
														id={tab.id}
														aria-controls={`${tab.id}-content`}
														aria-selected={true}
														isActive={activeTab === tab.id}
														onClick={() => {
															setActiveTab(tab.id);
														}}
														key={index}
													>
														{tab.tabName}
													</FilterButton>
												);
											})}
										</FilterButtonGroup>
									)}

									{tabs?.map((tab: TabModel, index: number) => {
										return (
											<div
												role="tabpanel"
												id={`${tab.id}-content`}
												key={index}
												className={clsx(
													activeTab === tab.id ? 'block' : 'hidden'
												)}
											>
												{tab.text && (
													<EpiFragments fragments={tab.text.fragments} />
												)}
											</div>
										);
									})}
								</>
							) : (
								tabs?.map((tab: TabModel, index: number) => {
									return (
										<FilterButtonAccordion
											text={tab.tabName}
											onClick={() => handleAccordionClick(tab.id)}
											id={tab.id}
											expanded={activeTab === tab.id}
											key={index}
											className="mb-1"
										>
											<div className="px-4">
												{tab.text && (
													<EpiFragments fragments={tab.text.fragments} />
												)}
											</div>
										</FilterButtonAccordion>
									);
								})
							)}
						</div>
					</Cell>
				</Grid>
				{contentArea && contentArea.length > 0 && (
					<EpiContentArea content={contentArea} />
				)}
			</BaseLayout>
		);
	};

export default CriteriaServiceRequirementPageV2;
